<template>
  <div class="mb-12">
    <v-container>
      <v-row align-md="center" class="fill-height">
        <v-col sm="4">
          <h3 class="secondary--text mb-1">
            KAHEV Akademi'ye
          </h3>
          <h1 class="primary--text font-weight-bold mb-4">Hoş geldiniz!</h1>
          <p>
            Kadın Hekimler Eğitime Destek Vakfı'nın bir markası olan KAHEV
            Akademi, toplumun bilinçlendirilmesi amacıyla tasarlanmış bir
            e-öğrenme platformudur.
          </p>
        </v-col>
        <v-col sm="8" class="fill-height">
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                BUGÜN
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Gün</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Hafta</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Ay</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 gün</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>

          <v-sheet height="400">
            <v-calendar
              ref="calendar"
              locale="tr"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card
                color="grey lighten-4"
                min-width="300"
                max-width="360"
                flat
                elevation="12"
              >
                <v-toolbar dark :color="selectedEvent.color" elevation="0">
                  <v-toolbar-title>
                    {{ selectedEvent.name }}
                  </v-toolbar-title>
                  <v-spacer />
                  <v-btn icon @click="selectedOpen = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <h3>{{ selectedEvent.courseName }} Eğitimi</h3>
                  <p>
                    {{
                      new Date(selectedEvent.startDate).toLocaleString(
                        "tr-TR",
                        {
                          dateStyle: "long",
                          timeStyle: "short"
                        }
                      )
                    }}
                  </p>
                  <p class="font-weight-bold primary--text mb-0">
                    {{ selectedEvent.instructor }}
                  </p>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import firebase from "@/plugins/firebase";

export default Vue.extend({
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Ay",
      week: "Hafta",
      day: "Gün",
      "4day": "4 Gün"
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: []
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;

        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);

      const query = await firebase
        .firestore()
        .collection("/apps/kahev-akademi/lessons")
        .where("startDate", ">=", min)
        .where("startDate", "<=", max)
        .where("disabled", "==", false)
        .get();

      if (!query.empty) {
        query.forEach(async doc => {
          const id = doc.id;
          const desc = doc.data().desc;
          const startDate = doc.data().startDate.toDate();
          const course = await this.getCourse(doc.data().courseId);
          const eventStart = doc.data().startDate.toDate();
          const eventEnd = new Date(eventStart.toString());
          eventEnd.setHours(eventStart.getHours() + 1);

          if (doc.exists) {
            events.push({
              id: id,
              name: doc.data().name,
              startDate: startDate,
              courseName: course.name,
              courseUrlCode: course.urlCode,
              instructor: course.instructor,
              details: desc,
              start: eventStart,
              end: eventEnd,
              color: "#6b226d",
              timed: true
            });
          }
        });
      }

      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    async getCourse(id) {
      let returnVal = null;
      try {
        const doc = await firebase
          .firestore()
          .doc(`/apps/kahev-akademi/courses/${id}`)
          .get();

        if (doc.exists) {
          const course = {
            id: doc.id,
            name: doc.data().name,
            instructor: doc.data().instructor,
            urlCode: doc.data().urlCode
          };

          returnVal = course;
        }
      } catch (err) {
        console.error(`${id} id'li eğitim bilgileri alınamadı: ${err.message}`);
      }

      return returnVal;
    }
  }
});
</script>

<style lang="scss" scoped>
h1 {
  font-size: 3rem !important;
  font-weight: bold;
  position: relative;
  left: -3px;
}
</style>
